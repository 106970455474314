<template>
	<!-- 积分购首页 -->
	<div :class="themeClass">
		<div>
			<!-- tab背景 -->
			<div style="width:100%;">
				<div>
					<img :src="imgUrl" width="100%" height="220" style="vertical-align: middle;" />
				</div>
			</div>
			<!-- tab背景 -->
			<div class="pointcontent">
				<div class="tabBox flex-row">
					<div class="tabItem" :class="tabIndex==item.idS? 'tabItemActice':''" v-for="(item,index) in tabList"
						@click="tabClick(item)">
						{{item.name}}
					</div>
				</div>
				<!-- 店铺列表 -->
				<div v-loading="pointLoading" style="min-height:400px;margin-bottom: 30px;">
					<div v-if="shopGoodsData.length >0" class="shopBox margin-t-20"
						v-for="(item,index) in shopGoodsData">
						<div class="shopTitle">
							<div style="display: flex;justify-content: center;align-items: center;">
								<div class="imgBox">
									<img :src="item.shop.fShopLogoPath" width="30px" height="30px">
								</div>
								<div class="shopName margin-l-10">{{item.shop.fShopName}}</div>
								<div class="margin-l-10 shopType">{{item.shop.fShopTypeName}}</div>
							</div>
							<div class="flex-row">
								<div class="flex-row-align-center font-size12">
									<img width="27px" heigh="30px" src="@/assets/miaosha.png" alt="" />
									<span class=" font-size22 font-weight700 font-color-000">{{showactivitytext}}</span>
									<countDowm :remainTime="item.fSeckillBillGoodsPriceShow.usedTime" :showBold='false' @countDownEnd="countDownEnd"
										:width="37" :height="28" :fontWeight="700" :fontSize="16"
										style="margin-top: 0px;margin-right: 30px; line-height: 28px;"
										numberColor="#fff" bgColor="#D50015" colonColor="#000000" :paddingRL="4">
									</countDowm>
								</div>
								<div class="moreBtn" @click="GoSeckkill(item.shop.fShopUnitID)">
									<div class="margin-r-5">更多</div>
									<div
										style="width: 18px;height: 18px;border:1px solid #000;border-radius: 50%;display: flex;align-items: center;justify-content: center;text-align: center;">
										<i class="el-icon-arrow-right font-color-000 font-size16 font-weight700"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="shopList ">
							<div class="shopItem margin-r flex-row-wrap"
								v-for="(item1,index1) in item.fSeckillBillGoodsPriceShow"
								:class="(index1+1)%4 != 0?'margin-r-12':''" @click="gotoSeckkillGoodsDetails(item1.fGoods.fGoodsID)">
								<div style="height:202px;width:100%;" class="flex-row-center-center">
									<img :src="item1.fGoods.fPicturePath" width="176px" height="176px">
								</div>
								<div class="margin-l-15 margin-r-15">
									<div class="GoodsNameline-show font-size13 font-color-333 ">
										{{item1.fSeckillBillGoodsPrice.fGoodsFullName}}
									</div>
									<div class="flex-row-space-between" style="width:227px;">
										<div>
											<div class="box-two-integral font-size20 font-weight700 font-color-FF0000"
												style="line-height: 35px;">
												{{item1.fSeckillBillGoodsPrice.fPrice}}
												<span
													class="font-size12 font-weight400">元/{{item1.fGoods.fUnitName}}</span>
											</div>
											<div class="flex-row-align-center">
												<div class="font-size12 font-color-333"
													style="width:75px;max-width: 75px;">
													剩余{{item1.fSeckillBillGoodsPrice.fLeaveAmount}}{{item1.fGoods.fUnitName}}
												</div>
												<div class="progress margin-l-3">
													<el-progress :percentage="item1.fSeckillBillGoodsPrice.fSeckillRatio" :color="'#d50015'"
														:show-text="false"></el-progress>
												</div>
											</div>
										</div>
										<div class="goTBtn" @click="gotoSeckkillGoodsDetails(item1.fGoods.fGoodsID)">
											抢
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 没数据时 -->
					<div v-if="showImge">
						<div
							style="margin:20px auto;width: 1100px;height:377px;background-color:#f5fafe;position: relative;">
							<div
								style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
								<div>
									<img src="@/assets/nogoods.png" width="100%">
									<div
										style="width: 103;font-size: 30px;line-height: 80px;font-weight: bold;color: #1144ab;text-align: center;letter-spacing: 5px;padding:0 10px;">
										活动正在筹划中...</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 没数据时 -->
				</div>
				<!-- 店铺列表 -->
			</div>
		</div>
	</div>

</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import countDowm from '@/components/countDown/countDown.vue';
	export default {
		components: {
			countDowm,
		},
		data() {
			return {
				imgUrl: require('@/assets/seckillBanner.png'),
				showImge: false,
				tabList: [{
						idS: 1,
						name: '正在秒杀',
						active: true
					},
					{
						idS: 2,
						name: '待开始',
						active: false
					},
				],
				tabIndex: 1,
				showactivitytext: "", //活动时间文字
				usedTime: 0, //倒计时的毫秒数
				shopGoodsData: [],
				pointLoading: false, //加载动画

				// showShop: false,
				// showShoptext: "收起店铺",
				// TabList: [],
				// hotShop: [], //接收店铺
				// tabId: 5,
				// ShowshopId: 5, //控制店铺选中效果的id
				// loadingShop: false, //店铺的加载动画
				// fEndBalance: 0, //积分
				// search: "", //商品的模糊查询
				// fIsExchange: 0, //可兑换商品
				// gePointlList: [], //积分购

				// pointcountDownTime: 0, // 积分购
				// isPointStartTime: false, //积分购
				// fShopUnitID: -1, //店铺id
				// // 当前页数
				// currentPage: 1,
				// // 每页条数
				// pagesize: 15,
				// // 总条数
				// total: 0,
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getShopGoods(); //正在秒杀
		},

		methods: {
			// tab点击事件
			tabClick(val) {
				this.tabIndex = val.idS;
				if (this.tabIndex == 1) {
					this.getShopGoods();
				} else if (this.tabIndex == 2) {
					this.getShopGoodsAfter()
				}
			},
			//更多按钮的点击事件
			GoSeckkill(e){
				if (this.tabIndex == 1) {
					this.$router.push({
						name: 'Seckkill',
						query: {
							shopId: e
						}
					})
				} else if (this.tabIndex == 2) {
					this.$message.warning('亲，请活动开始之后再抢购！')
					return
				}
				
			},
			// 抢按钮的点击事件
			gotoSeckkillGoodsDetails(item){
				if (this.tabIndex == 1) {
					this.$router.push({
						name: 'SeckkillGoodsDetails',
						query: {
							fGoodsID: item
						}
					})
				} else if (this.tabIndex == 2) {
					this.$message.warning('亲，请活动开始之后再抢购！')
					return
				}
			},
			// 倒计时结束事件
			countDownEnd() {
				if (this.tabIndex == 1) {
					this.getShopGoods();
				} else if (this.tabIndex == 2) {
					this.getShopGoodsAfter()
				}
			},
			//获取正在秒杀
			getShopGoods() {
				this.pointLoading = true;
				this.ApiRequestPost('/api/mall/ebactivity/seckill-bill-goods-price/get-shop-and-goods', {
						fAppType: 1,
						filter: "",
						sorting: ""
					})
					.then(res => {
							this.pointLoading = false;
							this.shopGoodsData = [];
							if (res.obj.items.length != 0) {
								res.obj.items.map((item, index) => {
									item.fSeckillBillGoodsPriceShow = item.fSeckillBillGoodsPriceShow.slice(0, 4)
									let start = new Date(item.fSeckillBillGoodsPriceShow[0].fSeckillBillGoodsPrice.fBeginTime).getTime(); //活动开始时间
									let end = new Date(item.fSeckillBillGoodsPriceShow[0].fSeckillBillGoodsPrice
										.fEndTime).getTime(); //活动结束时间
									let now = new Date().getTime();
									if (now > end) {
										console.log('活动已结束')
										this.showImge = true;
									}
									if (now >= start && now < end) {
										console.log(end - now)
										this.showactivitytext = "距离活动结束："
										item.fSeckillBillGoodsPriceShow.usedTime = (end - now)
										console.log(this.usedTime)

									}
								})
								this.shopGoodsData = res.obj.items;
								this.showImge = false
							} else if (res.obj.items.length == 0) {
								this.showImge = true;
							}
						},
						error => {
							this.pointLoading = false;
						});
			},
			getShopGoodsAfter() {
				this.pointLoading = true;
				this.ApiRequestPost('/api/mall/ebactivity/seckill-bill-goods-price/get-shop-and-goods-After', {
						fAppType: 1,
						filter: "",
						sorting: ""
					})
					.then(res => {
							this.pointLoading = false;
							this.shopGoodsData = [];
							if (res.obj.items.length != 0) {
								res.obj.items.map((item, index) => {
									item.fSeckillBillGoodsPriceShow = item.fSeckillBillGoodsPriceShow.slice(0, 4)
									// let beforeshow = new Date(this.activityInfo.fRemindTime).getTime(); //活动开始展示时间
									let start = new Date(item.fSeckillBillGoodsPriceShow[0].fSeckillBillGoodsPrice
										.fBeginTime).getTime(); //活动开始时间
									let end = new Date(item.fSeckillBillGoodsPriceShow[0].fSeckillBillGoodsPrice
										.fEndTime).getTime(); //活动结束时间
									let now = new Date().getTime();
									if (now < start) {
										console.log(start - now)
										this.showactivitytext = "距离活动开始："
										item.fSeckillBillGoodsPriceShow.usedTime = (end - now)
									}
									if (now > end) {
										console.log('活动已结束')
										this.showImge = true;
									}
									if (now >= start && now < end) {
										console.log(end - now)
										this.showactivitytext = "距离活动结束："
										item.fSeckillBillGoodsPriceShow.usedTime = (end - now)

									}
								})
								this.shopGoodsData = res.obj.items;
								this.showImge = false
							} else if (res.obj.items.length == 0) {
								this.showImge = true;
							}
						},
						error => {
							this.pointLoading = false;
						});
			},

		},
	};
</script>
<style scoped lang="scss">
	.tabBox {
		width: 1100px;
		height: 57px;
		background: #ffffff;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
	}

	.pointcontent {
		min-height: 550px;
		width: 1100px;
		height: auto;
		margin: 0 auto;
		// overflow: hidden;
		position: relative;

		.shop {
			position: absolute;
			left: 0;
			top: -53px;
		}
	}

	.tabItem {
		width: 200px;
		line-height: 57px;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		color: #000000;
	}

	.tabItemActice {
		width: 200px;
		line-height: 57px;
		background: #D50015;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		color: #ffffff;
	}

	.shopBox {
		width: 1100px;
		height: 412px;
		background: #FDF0D0;
	}

	.shopTitle {
		padding: 20px 15px 16px 15px;
		display: flex;
		justify-content: space-between;
	}

	.imgBox {
		width: 40px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #e4e4e4;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.shopName {
		font-size: 22px;
		color: #000000;
		font-weight: 700;
	}

	.shopType {
		width: 51px;
		height: 22px;
		background: #ff0000;
		border-radius: 2px;
		text-align: center;
		line-height: 22px;
		color: #ffffff;
		font-size: 12px;
		font-weight: 700;
	}

	.moreBtn {
		width: 97px;
		height: 36px;
		background: #e49f10;
		border-radius: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		color: #000000;
	}

	.shopList {
		background-color: #F8F9FE;
		margin: 0 3px 0px 3px;
		padding: 14px;
		display: flex;
		justify-content: baseline;
		// justify-content: space-between;
		// justify-content: space-evenly;
		// justify-content: space-around;
		justify-items: auto;
		// align-items: center;
	}

	.shopItem {
		width: 256px;
		height: 300px;
		background: #ffffff;
		border: 1px solid #f2f2f2;
		border-radius: 5px;
	}

	.GoodsNameline-show {
		word-break: break-all;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 27px;
		height: 27px;
		line-height: 27px;
	}

	.goTBtn {
		width: 81px;
		height: 42px;
		background: #d50015;
		border-radius: 2px;
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		line-height: 42px;
		text-align: center;
		margin-top: 13px;
	}

	.progress {
		width: 65px;
	}
</style>